export const FirebaseConfig = {
	"projectId": "petitaxi-24726",
	"appId": "1:228075124681:web:d0a5d37ef68bee7b5b5a18",
	"databaseURL": "https://petitaxi-24726-default-rtdb.firebaseio.com",
	"storageBucket": "petitaxi-24726.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAAqYbejwwbQNuRgcwla7JBMzpIzNndc6E",
	"authDomain": "petitaxi-24726.firebaseapp.com",
	"messagingSenderId": "228075124681",
	"measurementId": "G-CX8MW2270R"
};